import React from "react";
import Chip from "@mui/material/Chip";
import { darken, lighten } from "@mui/material/styles";

const StatusChip = ({ status }) => {
  let color, bgColor, borderColor;
  const capitalize = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Set color and background color based on status
  switch (capitalize(status)) {
    case "Pending":
    case "InProgress":
      color = darken("#ff9800", 0.5); // orange
      bgColor = lighten("#ff9800", 0.8);
      borderColor = "#ff9800";
      break;
    case "Cancelled":
    case "Rejected":
    case "Paused":
    case "Blocked":
      color = darken("#f44336", 0.5); // red
      bgColor = lighten("#f44336", 0.8);
      borderColor = "#f44336";
      break;
    case "Sold":
    case "Active":
    case "Completed":
    case "Disbursed":
    case "Approved":
      color = darken("#4caf50", 0.5); // green
      bgColor = lighten("#4caf50", 0.8);
      borderColor = "#4caf50";
      break;
    default:
      color = darken("#e0e0e0", 0.6); // gray
      bgColor = lighten("#e0e0e0", 0.9);
      borderColor = darken("#e0e0e0", 0.4);
      break;
  }

  return (
    <Chip
      label={capitalize(status)}
      style={{
        color: color,
        backgroundColor: bgColor,
        borderRadius: "16px",
        padding: "0px 3px",
        fontSize: "12px",
        minWidth: "80px",
        border: `1px solid ${borderColor}`,
      }}
    />
  );
};

export default StatusChip;
