import { createContext, useEffect, useState, useMemo } from "react";
import Cookies from "js-cookie";
import useUserRoleProductType from "app/hooks/user/useUserRoleProductType";
import { useNavigate } from "react-router-dom";
// Create context with initial default values
const AuthContext = createContext({
  method: "JWT",
  login: () => {},
  logout: () => {},
  userRoleProductType: {}
});
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  // Initialize authentication state based on the presence of a token
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = Cookies.get("token");
    console.log("Initial token:", token);
    return !!token;
  });
  const { data: userRoleProductType } = useUserRoleProductType();
  useEffect(() => {
    const token = Cookies.get("token");
    console.log("Token in useEffect:", token);
    // Optional: You can add token validation logic here if needed (e.g., decode the token or check its expiration).
    setIsAuthenticated(!!token);
  }, []);
  const login = (token) => {
    console.log("Logging in with token:", token);
    Cookies.set("token", token, { sameSite: "Lax", secure: true });
    setIsAuthenticated(true);
    if (!userRoleProductType?.is_subscribed) {
      navigate("/plans");
    } else {
      navigate("/");
    }
  };
  const logout = () => {
    console.log("Logging out");
    Cookies.remove("token");
    setIsAuthenticated(false);
  };
  // Memoize the context value to prevent unnecessary re-renders
  const authContextValue = useMemo(
    () => ({
      isAuthenticated,
      login,
      logout,
      userRoleProductType
    }),
    [isAuthenticated, userRoleProductType]
  );
  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
export default AuthContext;
// import Cookies from "js-cookie";
// import useUserRoleProductType from "app/hooks/user/useUserRoleProductType";
// import { useLocation, useNavigate } from "react-router-dom";

// // Create context with initial default values
// const AuthContext = createContext({
//   method: "JWT",
//   login: () => {},
//   logout: () => {},
//   userRoleProductType: {}
// });

// export const AuthProvider = ({ children }) => {
//   const navigate = useNavigate();
//   const [isAuthenticated, setIsAuthenticated] = useState(() => {
//     const token = Cookies.get("token");
//     return !!token; // Boolean conversion to check if token exists
//   });

//   const [token, setToken] = useState(() => Cookies.get("token")); // Store the token in state
//   const { data: userRoleProductType } = useUserRoleProductType(isAuthenticated); // Fetch data only if authenticated

//   console.log("userRoleProductType-----------", userRoleProductType);
//   const { pathname } = useLocation();
//   console.log("pathname auth", pathname);

//   useEffect(() => {
//     const token = Cookies.get("token");
//     if (token) {
//       setIsAuthenticated(true);
//       setToken(token); // Update the token state
//     } else {
//       setIsAuthenticated(false);
//       setToken(null); // Clear token state if not authenticated
//     }
//   }, []);

//   useEffect(() => {
//     if (userRoleProductType?.is_subscription === true) {
//       console.log("-------------userRoleProductType--------------");
//       navigate("/");
//     } else {
//       navigate("/plans", { state: { token } });
//     }
//   }, [userRoleProductType, token]);

//   const login = (token) => {
//     Cookies.set("token", token, { sameSite: "Lax", secure: true });
//     setIsAuthenticated(true);
//     setToken(token); // Set token in state
//   };

//   const logout = () => {
//     Cookies.remove("token");
//     setIsAuthenticated(false);
//     setToken(null); // Clear token state on logout
//     navigate("/login"); // Redirect to login after logout
//   };

//   // Memoize the context value to prevent unnecessary re-renders
//   const authContextValue = useMemo(
//     () => ({
//       isAuthenticated,
//       login,
//       logout,
//       userRoleProductType
//     }),
//     [isAuthenticated, userRoleProductType]
//   );

//   return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
// };

// export default AuthContext;
