import { Box, TextField, Typography } from "@mui/material";
import React from "react";

const SharedLoanDataGridHeader = (props) => {
  const { searchTerm, setSearchTerm } = props;
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ padding: "20px", borderBottom: "1px solid #e9e9e9" }}
      >
        <Box>
          <Typography
            sx={{ fontSize: "17px", fontWeight: 700, color: "#002441" }}
          >
            Shared Loan Files
          </Typography>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "#7e8594" }}
          >
            See information about all your shared loan files{" "}
          </Typography>
        </Box>
        <Box display={"flex"} gap={"10px"} alignItems="center" flexWrap="wrap">
          <TextField
            variant="outlined"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
          />
        </Box>
      </Box>
    </>
  );
};

export default SharedLoanDataGridHeader;
