import { useQuery } from "@tanstack/react-query";
import { getSharedLoanList } from "../../api/loan/loan"; // Import the appropriate API function for fetching agent details

function useSharedLoanList(queryOptions = {}) {
  const query = useQuery({
    queryKey: ["sharedLoanList"], // Adjust the queryKey to match agent details
    queryFn: () => {
      return getSharedLoanList(); // Call the appropriate API function to fetch agent details
    },
    enabled: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    select: (response) => response.data, // Adjust this if the structure of your response is different
    onError: (error) => {
      console.error(error);
    },
    ...queryOptions,
  });

  return query;
}

export default useSharedLoanList;


