import React from "react";
import DataGrid from "app/components/DataGridTable";
import { styled, Box } from "@mui/material";
import useSharedLoanList from "app/hooks/loanhook/useSharedLoanList";
import StatusChip from "../material-kit/statusChip/StatusChip";
import SharedLoanDataGridHeader from "./SharedLoanDataGridHeader";
import { useMemo } from "react";
import { useState } from "react";
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

export default function SharedLoan() {
  const { data: sharedLoanList, isFetching: isFetchingSharedLoanList } =
    useSharedLoanList();
  const [searchTerm, setSearchTerm] = useState("");

  const rows = (sharedLoanList || []).map((loan) => ({
    id: loan?.loan_shared_history_id,
    name: loan?.loan?.customer_name,
    contact: loan?.loan?.contact_info,
    address: loan?.loan?.address,
    loanType: loan?.loan?.loan_type,
    bankName: loan?.loan?.bank_branch?.bank?.name,
    loanAmount: loan?.loan?.loan_amount,
    fileStatus: loan?.loan?.approval_status,
    leadBy: loan?.sender?.name,
    leadTo: loan?.receiver?.name,
  }));

  const filteredRows = useMemo(() => {
    return rows.filter((row) =>
      row?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "contact", headerName: "Contact", flex: 1 },
    { field: "address", headerName: "Client Address", flex: 1 },
    { field: "loanType", headerName: "Loan Type", flex: 1 },
    { field: "bankName", headerName: "Bank Name", flex: 1 },
    { field: "loanAmount", headerName: "Loan Amount", flex: 1 },
    {
      field: "fileStatus",
      headerName: "File Status",
      flex: 1,
      renderCell: (params) => <StatusChip status={params.value} />,
    },
    { field: "leadBy", headerName: "Lead By", flex: 1 },
    { field: "leadTo", headerName: "Lead To", flex: 1 },
  ];

  return (
    <Container>
      <Box sx={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={isFetchingSharedLoanList ? [] : filteredRows}
          columns={columns}
          pageSize={5}
          loading={isFetchingSharedLoanList}
          components={{ Header: SharedLoanDataGridHeader }}
          componentsProps={{
            header: {
              searchTerm,
              setSearchTerm,
            },
          }}
        />
      </Box>
    </Container>
  );
}
