import axios from "../axios";

export function postUserInvite(payload) {
  return axios.post(`/user_invite/`, payload);
}

export function UserInvite(payload) {
  return axios.post(`/user_invite/`, payload);
}

export function getUserInvite(payload) {
  return axios.get(`/agent/`, payload);
}

export function patchUserInvite(payload) {
  return axios.patch(`/user_invite/`, payload);
}

export default function postSignIn(payload) {
  return axios.post(`/signin/`, payload);
}

export function postSignUp(payload) {
  return axios.post(`/signup/`, payload);
}

export function getVerify(payload) {
  return axios.get(`/verify/?token=${payload.token}&user=${payload.user}`);
}

export function getUser() {
  return axios.get(`/user/`);
}

export function postLogout(payload) {
  return axios.post(`/logout/`, payload);
}

export function postForgotPassword(payload) {
  return axios.post(`/forgot-password/`, payload);
}

export function getOrganization() {
  return axios.get(`/organization/`);
}
export function getProfile() {
  return axios.get(`/user_profile/`);
}

export function getUserRoleProductType() {
  return axios.get(`/user_role_product_type/`);
}

export function getCommissionDetails(agentId) {
  const queryParam = {};

  if (agentId) {
    queryParam.agent_id = agentId;
  }

  // Make the API request
  return axios.get("/commission/", {
    params: queryParam, // Pass query parameters
  });
}