import { useQuery } from "@tanstack/react-query";
import { getUserRoleProductType } from "app/api/user";
// Import the appropriate API function for fetching agent details

function useUserRoleProductType(isAuthenticated, queryOptions = {}) {
  const query = useQuery({
    queryKey: ["userRoleProductType", isAuthenticated], // Adjust the queryKey to match agent details
    queryFn: () => {
      return getUserRoleProductType(); // Call the appropriate API function to fetch agent details
    },
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    select: (response) => response.data, // Adjust this if the structure of your response is different
    onError: (error) => {
      console.error(error);
    },
    ...queryOptions
  });

  return query;
}

export default useUserRoleProductType;
