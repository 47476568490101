export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const drawerType = {
  view: "view",
  modify: "modify",
  create: "create",
  uploadInvoice: "uploadInvoice",
  share: "share"
};
export const loanTypes = [
  { value: "home_loan", label: "Home Loan" },
  { value: "personal_loan", label: "Personal Loan" },
  { value: "auto_loan", label: "Auto Loan" },
  { value: "education_loan", label: "Education Loan" },
  { value: "bussiness_loan", label: "Business Loan" }
];
