import { Navigate, useLocation } from "react-router-dom";
import useAuth from "app/hooks/useAuth";
import { useEffect } from "react";
export default function AuthGuard({ children, auth }) {
  const { userRoleProductType, isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  console.log("pathname", pathname);
  if (!isAuthenticated) {
    return <Navigate replace to="/signin" state={{ from: pathname }} />;
  }
  // console.log("userRolePRoductType", userRolePRoductType.role);
  // // Check if the user's role is authorized for this route
  // if (auth && !auth.includes(userRolePRoductType?.role)) {
  //   return <Navigate to="/403" />;
  // }
  // // Check if the user has an active subscription
  // if (!userRolePRoductType.is_subscription) {
  //   return <Navigate to="/plans" />;
  // }

  const allowedPaths = [
    "/support",
    "/change-password",
    "/payment-confirmation",
    "/profile",
  ];
  if (
    userRoleProductType?.subscription === null &&
    pathname !== "/plans" &&
    !allowedPaths.includes(pathname)
  ) {
    return <Navigate replace to="/plans" />;
  }
  if (
    userRoleProductType?.subscription === "Pending" &&
    pathname !== "/payment-confirmation" &&
    !allowedPaths.includes(pathname)
  ) {
    return <Navigate replace to="/payment-confirmation" />;
  }
  if (userRoleProductType?.is_profile === false && pathname !== "/profile") {
    return <Navigate replace to="/profile" />;
  }

  return <>{children}</>;
}
