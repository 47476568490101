import axios from "../../axios";

export function getAgentDetails() {
  return axios.get(`/agent/`);
}

export function getAgentCommission() {
  return axios.get(`/commission_percentage/`);
}

export function patchAgentCommision(payload) {
  return axios.patch(`/commission_percentage/`, payload);
}

export function postAgentCommission(payload) {
  return axios.post(`/commission_percentage/`, payload);
}

export function getBlokedLoanDetails() {
  return axios.get(`/agent/`);
}

export function getLoanWiseCommission() {
  return axios.get(`/agent/`);
}

export function getSpendingOnAgent() {
  return axios.get(`/agent_wise_commission/`);
}

export function getLoanDetails() {
  return axios.get(`/loan_lead/`);
}

export function getBankDetails() {
  return axios.get(`/bank/`);
}

export function getBankLoanSlabList() {
  return axios.get(`/bank_loan_slab/`);
}

export function getSharedLoanList() {
  return axios.get(`/loan_shared_history/`);
}

export function getBankBranchDetails(bankid) {
  return axios.get(`/bank_branch/`, { params: bankid });
}
export default function getAgentsList(payload) {
  return axios.post(`/signin/`, payload);
}

export function postApprovedLoansList(payload) {
  return axios.post("/loan_lead_apply/", payload);
}



export function patchCommission(payload) {
  return axios.patch(`/commission/`, payload);
}